import React, {
  MouseEvent,
  useLayoutEffect,
  useContext,
  useRef,
  useState,
} from 'react'
import { ThemeContext } from '../context/ThemeContext'
import { useLocation } from '@reach/router'
import Img from 'gatsby-image'
import { graphql, Link, useStaticQuery, navigate } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'

const Projects = () => {
  const { showListView, toggleListView, changeTheme } = useContext(ThemeContext)
  const location = useLocation()

  const data = useStaticQuery(graphql`
    query {
      allSanityProject(sort: { fields: [order], order: ASC }) {
        nodes {
          order
          id
          slug {
            current
          }
          title
          type
          main_img {
            alt
            asset {
              fluid(maxWidth: 1400) {
                ...GatsbySanityImageFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  `)

  const projects = data.allSanityProject.nodes

  useLayoutEffect(() => {
    changeTheme(showListView ? 'list-view' : location.pathname)
  })

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault()
    toggleListView()
  }

  const sliderRef = useRef(null)

  const handleSlickNext = () => {
    sliderRef?.current.slickNext()
  }

  const handleSlickPrev = () => {
    sliderRef?.current.slickPrev()
  }

  const settings = {
    arrows: false,
    ref: sliderRef,
    responsive: [
      {
        breakpoint: 4000,
        settings: {
          variableWidth: true,
          centerMode: true,
        },
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 2.72,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.6,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1.2,
        },
      },
    ],
  }

  const [isSwiping, setIsSwiping] = useState<boolean>(false)
  const handleCarouselClick = (path: string) => {
    navigate(path)
  }

  const [hasLoaded, setHasLoaded] = useState<boolean>(false)
  const handleOnLoad = () => {
    setHasLoaded(true)
  }

  return (
    <Layout>
      <SEO title="Projects" />
      <div className="container-theme">
        <div className="container-fluid projects">
          <section className="projects-top row">
            <div className="projects-top-info col-5 offset-3 col-6-mob offset-0-mob">
              <div className="wrap d-flex align-items-center">
                <div className="title col">
                  <h1>Projects</h1>
                </div>
                <div className="button-wrap col">
                  <button
                    className="btn projects-list-view d-none d-lg-block"
                    onClick={e => handleClick(e)}
                  >
                    {!showListView ? 'List view' : 'Image view'}
                  </button>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      {!showListView ? (
        <div className="projects-carousel-wrap container-theme">
          <div className="row">
            <Slider {...settings}>
              {projects.map((project, index) => {
                if (project?.main_img?.asset?.fluid) {
                  return (
                    <div
                      className="col-4 carousel-item"
                      key={index}
                      style={{ width: 587 }}
                      onMouseMove={() => setIsSwiping(true)}
                      onMouseDown={() => setIsSwiping(false)}
                      onMouseUp={() =>
                        !isSwiping && handleCarouselClick(project.slug.current)
                      }
                    >
                      <h2 className="text-std mb-3">{project.title}</h2>
                      <Img
                        className={`carousel-image ${
                          hasLoaded ? 'loaded fade-in' : ''
                        }`}
                        fluid={project.main_img.asset.fluid}
                        loading="eager"
                        onLoad={() => handleOnLoad()}
                        backgroundColor="#BDB9B2"
                        fadeIn
                        durationFadeIn={800}
                      />
                    </div>
                  )
                }
              })}
            </Slider>
          </div>
          <button
            type="button"
            className="carousel-side-right"
            aria-label="Next slide"
            onClick={handleSlickNext}
          />
          <button
            type="button"
            className="carousel-side-left"
            aria-label="Previous slide"
            onClick={handleSlickPrev}
          />
        </div>
      ) : (
        <div className="container-theme">
          <div className="container-fluid">
            <section className="list-view row justify-content-end">
              <div className="col-5 col-6-mob">
                <article className="projects-list gm-list">
                  <div className="gm-list-header row">
                    <div className="col-list-equal text-sm">
                      <span className="gm-list-heading">Project</span>
                    </div>
                    <div className="col-list-equal text-sm">
                      <span className="gm-list-heading">Type</span>
                    </div>
                    <div className="col-list-divider"></div>
                  </div>
                  <div className="gm-list-content row">
                    {projects.map((project, index) => (
                      <Link to={project.slug.current} key={index}>
                        <div className="gm-list-content row" key={index}>
                          <div className="col-list-equal my-auto">
                            <h2 className="gm-list-title text-std">
                              {project.title}
                            </h2>
                          </div>
                          <div className="col-list-equal my-auto">
                            <div className="projects-list-type">
                              {project.type}
                            </div>
                          </div>
                          <div className="col-list-divider"></div>
                        </div>
                      </Link>
                    ))}
                  </div>
                </article>
              </div>
            </section>
          </div>
        </div>
      )}
    </Layout>
  )
}

export default Projects
